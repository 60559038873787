<template>
	<dialog-component class="create-post" @close="onClose">
		<template #header-title>
			{{ isCreating ? 'Новая публикация' : 'Редактирование публикации' }}
		</template>
		<create-video-media :is-sending="isSending" v-show="hasAttachedVideo" />
		<create-photo-media
			ref="photo"
			:is-sending="isSending"
			v-model="post.photo"
			@crop="onCrop($event)"
			v-show="hasAttachedPhoto && !hasAttachedVideo"
		/>
		<form-component ref="form">
			<textarea-component
				id="description"
				v-model="post.description"
				class="create-post-description"
				placeholder="Опиши свое настроение, расскажи небольшую историю или задай вопрос аудитории. Упоминай @никнейм, чтобы наградить кого-нибудь и не забудь #теги, чтобы быть на виду."
			/>
		</form-component>
		<!-- Закомпонентить поля -->
		<div class="create-post-fieldwrap">
			<div class="create-post-field" @click="openAudienceModal">
				<span class="create-post-field-withicon create-post-field-withicon--privation">Приватность</span>
				<span class="color-red create-post-field--chevron">{{ audienceList[post.audience] }}</span>
			</div>
			<div v-if="scheduledPostsEnabled">
				<div :class="['create-post-field', { 'create-post-field--disable': !post.can_be_scheduled }]">
					<span class="create-post-field-withicon create-post-field-withicon--date" @click="openCalendar">
						<span v-if="!datePublic">Дата и время публикации</span>
						<span v-else>Публикация</span>
					</span>
					<span @click="openCalendar" :class="['color-red', { 'create-post-field--chevron': !datePublic }]">{{
						datePublic
					}}</span>
					<span class="create-post-field--cross i-cross" @click="removeDatePublic" v-if="datePublic" />
				</div>

				<dialog-component v-show="calendarDialog" :title-left="true" @close="closeCalendarDialog">
					<template #header-title>Дата и время публикации</template>

					<div class="fields-group">
						<div
							:class="[
								'fields-group-input input-date',
								{ active: datePickerActive, error: showErrors && !currentDate }
							]"
							@click="toggleDatePicker"
						>
							<span v-if="currentDate">{{ currentDate | moment('DD.MM.YYYY') }}</span>
							<svg-icon name="calendar" size="24px" />
						</div>
						<div
							:class="[
								'fields-group-input input-time',
								{ active: timePickerActive, error: showErrors && !currentTime }
							]"
							@click="toggleTimePicker"
						>
							<span v-if="currentTime">{{ currentTime }}</span>
							<svg-icon name="clock" size="24px" />
						</div>
					</div>

					<Calendar
						:rows="1"
						:step="1"
						ref="calendar"
						class="new-post-calendar"
						:attributes="attributes"
						:selected-day="startDay"
						@day-click="pickDate"
						@exit-week-mode="removeWeekMode"
						:min-date="minDate"
						:max-date="maxDate"
						:week-mode="weekMode"
						:week-number="weekNumber"
						v-show="datePickerActive"
						:current-day="currentDate"
						:create-new-post="true"
					/>
					<div v-if="weekMode" class="schedule-detail-new-post">
						<div class="schedule-current-date">
							{{ getFullDate(day) }}
						</div>
					</div>
					<time-picker-component
						ref="timePicker"
						v-if="timePickerActive"
						@update="timerUpdate"
						:min-date-hour="minDateHour"
					/>
					<schedule-day-events v-if="weekMode" :show-more="false" :schedule-by-day="scheduleByDay" />

					<template #dialog-footer>
						<button-component @click="submitCalendarDialog">Готово</button-component>
					</template>
				</dialog-component>

				<div
					:class="['create-post-field', { 'create-post-field--disable': !datePublic || !post.can_be_scheduled }]"
					@click="changeNotification"
				>
					<span class="create-post-field-withicon create-post-field-withicon--notification">
						Уведомлять о выходе поста
					</span>
					<span :class="['switcher', { 'switcher--on': getNotification }]" />
				</div>
			</div>
		</div>
		<ButtonComponent :loading="isSending" @click="onSubmitPost">{{ submitButtonText }}</ButtonComponent>
		<dialog-component v-show="showAudienceModal" @close="closeAudienceModal">
			<template #header-title>Приватность</template>
			<label
				class="create-post-radio"
				v-for="(label, value) in audienceList"
				:key="value"
				@click="showAudienceModal = false"
			>
				<input hidden type="radio" :value="value" name="post-visibility" v-model="post.audience" />
				<span>{{ label }}</span>
			</label>
		</dialog-component>
		<dialog-component v-show="showValidationDialog" @close="hideValidationDialog">
			<template #header-title>Не подходит</template>
			<div class="create-post-error">
				<p>{{ validationError.message }}</p>
				<p>{{ validationError.description }}</p>
			</div>
		</dialog-component>
	</dialog-component>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useHomeStore } from '@/stores/home'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import { useGirlSchedule } from '@/stores/girlSchedule'
import { useModalStore } from '@/stores/modal'
import ScheduleDayEvents from '@/components/schedule/ScheduleDayEvents.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import TextareaComponent from '@/components/forms/TextareaComponent.vue'
import CreatePhotoMedia from '@/components/create-post/CreatePhotoMedia.vue'
import CreateVideoMedia from '@/components/create-post/CreateVideoMedia.vue'
import FormComponent from '@/components/forms/FormComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import TimePickerComponent from '@/components/forms/TimePickerComponent.vue'
import Calendar from '@/components/Calendar.vue'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'CreatePostDialog',
	components: {
		FormComponent,
		CreateVideoMedia,
		CreatePhotoMedia,
		TextareaComponent,
		DialogComponent,
		ButtonComponent,
		TimePickerComponent,
		Calendar,
		ScheduleDayEvents,
		SvgIcon
	},
	data() {
		return {
			showAudienceModal: false,
			getNotification: false,
			datePublic: '',
			calendarDialog: false,
			timePickerActive: false,
			datePickerActive: true,
			currentTime: null,
			currentDate: null,
			formatDate: null,
			day: null,
			showErrors: false,
			weekMode: false,
			weekNumber: '',
			startDay: '',
			attributes: [
				{
					key: 'pick',
					highlight: {
						class: 'vc-highlight-pick'
					},
					dates: ''
				},
				{
					key: 'today',
					highlight: {
						class: 'vc-highlight-today'
					},
					dates: new Date()
				}
			]
		}
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['post', 'dateFromCalendar']),
		...mapState(useGirlSchedule, ['schedulesList', 'scheduleByDay']),
		...mapState(useCreatePostStore, [
			'isEditing',
			'isSending',
			'isCreating',
			'fullBase64',
			'audienceList',
			'croppedBase64',
			'hasAudienceList',
			'validationError',
			'showValidationDialog'
		]),
		...mapState(useGirlProfileStore, ['id', 'nickname', 'avatar', 'verification_status', 'scheduled_posts']),
		hasAttachedVideo() {
			const { video, video_id, video_preview_id } = this.post
			return Boolean(video_id || video_preview_id || video)
		},
		hasAttachedPhoto() {
			return Boolean(this.fullBase64 || this.croppedBase64 || this.post.photo)
		},
		scheduledPostsEnabled() {
			return import.meta.env.VITE_SCHEDULED_POSTS_ENABLED === 'true'
		},
		minDate() {
			const nowDate = new Date()
			const futureDate = new Date(nowDate.getTime() + this.scheduled_posts.range * 60 * 60 * 1000)
			if (nowDate.getDate() !== futureDate.getDate()) {
				return futureDate.toString()
			}
			return nowDate.toString()
		},
		maxDate() {
			return this.schedulesList?.end_date
		},
		minDateHour() {
			if (!this.currentDate || this.selectedDateIsClose(this.currentDate)) {
				let newDate = new Date()
				newDate = new Date(newDate.getTime() + this.scheduled_posts.min_delay * 1000).getHours() + 1
				return newDate !== 24 ? newDate : 0
			}
			return 0
		},
		submitButtonText() {
			if (this.datePublic) return 'Запланировать пост'
			return 'Опубликовать'
		}
		// hasAttachedMedia() {
		// 	return this.hasAttachedPhoto || this.hasAttachedVideo
		// }
	},
	methods: {
		...mapActions(useVideoUploadStore, ['resetVideo']),
		...mapActions(useGirlSchedule, ['getSchedulesList', 'getScheduleByDay']),
		...mapActions(useCreatePostStore, [
			'reset',
			'setMode',
			'getAudienceList',
			'editPostWithPhoto',
			'editPostWithVideo',
			'submitPostWithPhoto',
			'createPostWithVideo',
			'hideValidationDialog',
			'setDelayedPost'
		]),
		...mapActions(useCreatePostStore, ['closeCreatePostDialog']),
		...mapActions(useHomeStore, ['getPosts', 'resetPosts']),
		...mapActions(useModalStore, ['openVerificationNeedsDialog']),
		...mapActions(useGirlSchedule, ['resetSchedulePosts']),
		removeWeekMode() {
			this.weekMode = false
		},
		changeNotification() {
			if (this.post.can_be_scheduled && this.currentDate) {
				this.getNotification = !this.getNotification
				this.setPublicDate()
			}
		},
		async pickDate(day) {
			if (day.isDisabled) {
				return
			}
			if (this.day && this.day.getTime() === day.date.getTime()) {
				this.weekMode = true
				return
			}
			const chooseDate = this.$moment(day.date).toDate()
			this.currentDate = chooseDate
			this.attributes[0].dates = day.date
			this.weekNumber = String(day.week)
			this.day = day.date
			this.weekMode = true
			this.setPublicDate()
			this.removeUnvalidTime()
			await this.getScheduleByDay(day.id)
		},
		removeUnvalidTime() {
			if (this.selectedDateIsClose(this.currentDate)) {
				this.currentTime = null
				this.datePublic = null
			}
		},
		setPublicDate() {
			if (this.currentTime && this.currentDate) {
				this.datePublic = `${this.$moment(this.day).format('DD.MM.YYYY')} в ${this.currentTime}`
				const time = this.currentTime.split(':')
				this.formatDate = this.$moment(this.day).add(time[0], 'hour').add(time[1], 'minute').toDate()
				this.setDelayedPost({ date: this.formatDate.toISOString(), notification: this.getNotification })
			}
		},
		openCalendar() {
			if (this.post.can_be_scheduled) {
				this.calendarDialog = true
			}
		},
		timerUpdate(time) {
			if (time) {
				this.currentTime = time
				this.setPublicDate()
			}
		},
		toggleTimePicker() {
			this.datePickerActive = false
			this.timePickerActive = true
		},
		toggleDatePicker() {
			this.timePickerActive = false
			this.datePickerActive = true
		},
		closeCalendarDialog() {
			this.calendarDialog = false
		},
		submitCalendarDialog() {
			if (this.datePublic) {
				this.calendarDialog = false
			} else this.showErrors = true
		},
		removeDatePublic() {
			if (this.post.can_be_scheduled) {
				this.datePublic = null
				this.getNotification = false
				this.currentTime = null
				this.currentDate = null
				this.startDay = ''
				this.selectedDay = null
				this.attributes[0].dates = null
				this.weekMode = false
				this.day = false
				this.showErrors = false
				this.$refs.calendar.removeDate()
				this.setDelayedPost(false, false)
			}
		},
		onClose() {
			this.setMode('')
			this.reset()
			this.resetVideo()
			this.$emit('close')
		},
		onCrop(e) {
			this.post.crop = e
		},
		closeAudienceModal() {
			this.showAudienceModal = false
		},
		openAudienceModal() {
			this.showAudienceModal = true
		},
		getFullDate(day) {
			return new Intl.DateTimeFormat('ru-RU', {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			}).format(new Date(day))
		},
		onSubmitPost() {
			if (this.hasAttachedVideo) {
				this.onSubmitPostWithVideo()
			} else {
				this.onSubmitPostWithPhoto()
			}
		},
		async onSubmitPostWithPhoto() {
			try {
				if (this.isEditing) {
					await this.editPostWithPhoto()
				} else {
					await this.submitPostWithPhoto()
				}
				await this.onSuccessCreate()
			} catch (error) {
				this.$refs.form.setErrors(error.response.data.errors)
			}
		},
		async onSubmitPostWithVideo() {
			try {
				if (this.isEditing) {
					await this.editPostWithVideo()
				} else {
					await this.createPostWithVideo()
				}
				this.resetVideo()
				await this.onSuccessCreate()
			} catch (error) {
				this.$refs.form.setErrors(error.response.data.errors)
			}
		},
		convertScheduleToCalendar() {
			for (let i = 0; i < this.schedulesList?.items?.length; i += 1) {
				this.attributes.push({
					key: this.schedulesList.items[i].date,
					dot: !!this.schedulesList.items[i].scheduled_posts_count,
					bar: !!this.schedulesList.items[i].rejected_posts_count,
					dates: this.schedulesList.items[i].date
				})
			}
		},
		async onSuccessCreate() {
			this.closeCreatePostDialog()
			this.setMode('')
			this.resetPosts()
			this.resetSchedulePosts()
			if (this.$route.name === 'home') {
				await this.getPosts()
			} else {
				this.$router.push({ name: 'home' })
			}
			if (this.verification_status === 'needs') {
				this.openVerificationNeedsDialog()
			}
		},
		selectedDateIsClose(secondDate) {
			const diffInMilliseconds = secondDate.getTime() - new Date().getTime()
			const DelayInMilSeconds = this.scheduled_posts.min_delay * 1000
			return diffInMilliseconds <= DelayInMilSeconds
		},
		getWeekNumber(date) {
			const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
			const daysOffset = firstDayOfMonth.getDay()
			const firstFullWeekStart = 1 + ((7 - daysOffset) % 7)
			const day = date.getDate()
			const weekNumber = Math.ceil((day - firstFullWeekStart) / 7) + 1
			return String(weekNumber)
		},
		addZeroToNumber(number) {
			return String(number).padStart(2, '0')
		},
		setCurrentDate(momentDay, newPost) {
			let newDay
			if (this.selectedDateIsClose(momentDay.toDate()) && newPost) {
				newDay = this.$moment().add(this.scheduled_posts.min_delay / 3600 + 1, 'hours')
			}
			const hour = this.addZeroToNumber(newDay ? newDay.hour() : momentDay.hour())
			const minutes = this.addZeroToNumber(newDay ? 0 : momentDay.minutes())
			this.currentDate = newDay
				? newDay.set({ hour: 0, minutes: 0 }).toDate()
				: momentDay.set({ hour: 0, minutes: 0 }).toDate()
			this.currentTime = `${hour}:${minutes}`
			this.day = this.currentDate
			this.setPublicDate()
			this.startDay = this.currentDate.toString()
			this.weekMode = true
			this.weekNumber = this.getWeekNumber(this.day)
			this.getScheduleByDay(momentDay.format('YYYY-MM-DD'))
		}
	},
	mounted() {
		this.getAudienceList()
	},
	async beforeMount() {
		if (this.scheduledPostsEnabled) {
			await this.getSchedulesList()
			this.convertScheduleToCalendar()
			if (!this.isEditing && this.dateFromCalendar) {
				this.setCurrentDate(this.$moment(this.dateFromCalendar), true)
			}
			if (this.isEditing && this.post.should_be_published_at) {
				this.setCurrentDate(this.$moment(this.post.should_be_published_at))
			}
		}
	}
}
</script>

<style>
.create-post > .dialog > .dialog-body {
	height: 100%;
}
.create-post-description .form-field {
	margin-bottom: 9px;
	outline: none;
	min-height: 134px;
}

.create-post-description label {
	display: none;
}

.create-post-description .validation-list {
	margin-bottom: 14px;
}

.create-post-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.create-post-avatar {
	width: 40px;
	height: 40px;
	margin-right: 12px;
	border-radius: 50%;
}

.create-post-nickname {
	font-weight: 700;
}

.create-post-top-btn {
	font-size: 14px;
	color: var(--red);
}

.create-post .upload-image-preview {
	margin-bottom: 12px;
}

.create-post-image-controls {
	position: absolute;
	right: 20px;
	bottom: 20px;
	z-index: 5;
	display: flex;
	gap: 13px;
	margin: auto;
	left: 0;
	right: 0;
	width: 100%;
	justify-content: center;
}

.create-post-control {
	font-size: 14px;
	display: flex;
	background: rgba(25, 28, 34, 0.5);
	border-radius: 100px;
	color: #fff;
	border: none;
	padding: 9px;
	vertical-align: center;
}

.create-post-control span {
	margin-left: 5px;
	font-size: 12px;
	font-weight: 700;
}

.create-post-crop-description {
	font-size: 16px;
	line-height: 20px;
	margin: 32px 0;
	text-align: center;
	color: var(--text-gray);
}

.create-post-radio {
	padding: 4px 0;
}

.create-post-radio input:checked + span {
	color: var(--red-secondary);
}

.create-post-radio:not(:last-child) {
	margin-bottom: 12px;
}

.create-post .dialog-body {
	max-height: calc(100svh - 144px);
	overflow: scroll;
}

.create-post-error {
	font-size: 16px;
	line-height: 20px;
	color: var(--text-gray);
}

.create-post p:not(:last-child) {
	margin-bottom: 10px;
}

.create-post .input-image {
	visibility: hidden;
}

.create-post .input-image .validation-list {
	display: none;
}

.create-post .dialog-title {
	margin-left: auto;
	margin-right: auto;
}

.dialog-title.align-left {
	margin-left: 0;
}

.post-media-data {
	padding: 0;
}

.create-post-fieldwrap {
	margin-bottom: 35px;
}

.create-post-field {
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	color: #bebec9;
	justify-content: space-between;
	border-top: 0.5px solid rgba(67, 72, 86, 0.5);
	cursor: pointer;
	display: flex;
	align-items: center;
}

.create-post-field-withicon {
	display: flex;
	align-items: center;
}

.create-post-field-withicon::before {
	content: '';
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background-repeat: no-repeat;
}

.create-post-field-withicon--privation::before {
	background: transparent url('@/assets/images/icons/eye.svg') no-repeat center / cover;
}
.create-post-field-withicon--date {
	flex: 1;
}
.create-post-field-withicon--date::before {
	background: transparent url('@/assets/images/icons/calendar-days.svg') no-repeat center / cover;
}

.create-post-field-withicon--notification::before {
	background: transparent url('@/assets/images/icons/bell.svg') no-repeat center / cover;
}

.create-post-field:last-child {
	border-bottom: 0.5px solid rgba(67, 72, 86, 0.5);
}

.color-red {
	color: #ed326a;
}

.create-post-field--disable {
	opacity: 30%;
}

.create-post-field--chevron {
	display: flex;
	align-items: center;
}

.create-post-field--chevron::after {
	width: 17px;
	height: 24px;
	content: '';
	background: transparent url('@/assets/images/icons/chevron.svg') no-repeat center / cover;
}

.switcher {
	display: flex;
	height: 20px;
	width: 37px;
	background-color: rgba(114, 114, 129, 0.5);
	border-radius: 14px;
	transition: background 0.3s ease-in-out;
}

.switcher::before {
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 14px;
	background-color: #bebec9;
	transition: transform 0.3s ease-in-out;
}

.switcher--on {
	background-color: rgba(237, 50, 106, 0.5);
}

.switcher--on::before {
	transform: translateX(20px);
}

.create-post-field--cross {
	color: #727281;
	font-size: 24px;
}

.fields-group {
	display: flex;
	align-items: center;
}
.fields-group + .datepicker-container {
	margin-top: 16px;
}
.fields-group-input {
	flex: 1;
	height: 41px;
	background: rgba(44, 45, 55, 1);
	border: 1px solid rgba(67, 72, 86, 1);
	transition:
		border-color 0.2s ease-in-out,
		background-color 0.2s ease-in-out,
		color 0.2s ease-in-out;
	font-size: 16px;
	border-radius: 12px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	padding: 5px 14px;
}
.fields-group-input.active {
	border-color: #ffffff;
}
.fields-group-input.error {
	border-color: rgba(237, 50, 50, 1);
}
.fields-group-input svg {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
}
.fields-group-input.error svg {
	color: rgba(237, 50, 50, 1);
}
.input-time {
	margin-left: 12px;
	max-width: 111px;
}
.schedule-current-date {
	font-size: 14px;
	text-align: center;
	color: var(--semi-transparent);
	border-bottom: 1px solid rgba(67, 72, 86, 0.5);
	padding: 4px 0 10px 0;
}
.schedule-current-date:first-letter {
	text-transform: uppercase;
}
</style>
