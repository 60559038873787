<template>
	<div class="post-photo-component post-media-data">
		<div class="relative">
			<upload-image-preview v-if="preview" :src="preview" />
			<input-media
				ref="mediaInput"
				v-model="post.photo"
				@error="onError($event)"
				:rules="{ minDimensions: [500, 217] }"
				@loadend="onLoadEnd"
				format-file="image/*"
			/>
			<div v-if="preview" class="create-post-image-controls">
				<button type="button" class="create-post-control" @click="onEdit">
					<i class="i-pen" />
					<span>Редактировать</span>
				</button>
			</div>
		</div>
		<dialog-component v-if="showCropModal" class="edit-photo" @close="hideCropModal">
			<template #header-title>Редактирование фото</template>
			<div class="cropper-wrapper">
				<cropper
					ref="cropper"
					class="cropper"
					:src="previewCropper"
					:min-width="minStencilWidth"
					:resize-image="false"
					:init-stretcher="initStretcher"
					:stencil-props="{
						minAspectRatio: 0.75,
						maxAspectRatio: 2.3
					}"
					:default-size="defaultSize"
					:default-position="defaultPosition"
				/>
			</div>
			<div class="cropper-btngroup">
				<ButtonComponent color="transparent" :loading="isLoadingImage" @click="openMediaChoose">
					Изменить фото
				</ButtonComponent>
				<ButtonComponent color="primary" :loading="isLoadingImage" @click="getCroppedImage">
					Сохранить изменения
				</ButtonComponent>
			</div>
		</dialog-component>
	</div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import bodyScroll from '@/helpers/bodyScroll'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import UploadImagePreview from '@/components/UploadImagePreview.vue'
import InputMedia from '@/components/create-post/InputMedia.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

const validationErrorDescriptions = {
	minDimensions: 'Выбери другое фото.',
	size: 'Попробуй уменьшить размер в каком-нибудь приложением.',
	imageExt: 'Переделай в один из этих форматов в каком-нибудь приложении.'
}

export default {
	name: 'CreatePhotoMedia',
	components: {
		DialogComponent,
		UploadImagePreview,
		ButtonComponent,
		Cropper,
		InputMedia
	},
	data() {
		return {
			showCropModal: false,
			isLoadingImage: false,
			minStencilWidth: 1000,
			coordinates: null
		}
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['croppedBase64', 'fullBase64']),
		...mapState(useCreatePostStore, ['photoAsFile', 'isCreating', 'post']),
		preview() {
			return this.previewSrc()
		},
		previewCropper() {
			return this.previewSrc(true)
		},
		defaultSize() {
			if (this.coordinates) {
				return {
					width: this.coordinates.width,
					height: this.coordinates.height
				}
			}
			return undefined
		},
		defaultPosition() {
			if (this.coordinates) {
				return {
					top: this.coordinates.top,
					left: this.coordinates.left
				}
			}
			return undefined
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['showValidationError', 'resetPhoto']),
		previewSrc(forCropper = false) {
			if (this.croppedBase64 && !forCropper) {
				return this.croppedBase64
			}
			if (typeof this.post.photo === 'string') {
				const t = forCropper ? `?t=${Date.now()}` : ''
				return `${this.post.photo}${t}`
			}
			if (this.fullBase64 && forCropper) {
				return this.fullBase64
			}
			return ''
		},
		openMediaChoose() {
			this.$refs.mediaInput.$refs.media.click()
		},
		onError(error) {
			const errorKey = Object.keys(error.failedRules)[0]
			this.showValidationError(error.failedRules[errorKey], validationErrorDescriptions[errorKey])
		},
		onLoadEnd(fileInfo) {
			this.coordinates = null
			if (fileInfo && fileInfo.loadSuccess) {
				this.post.photo_id = null
			}
		},
		onEdit() {
			this.showCropModal = true
		},
		hideCropModal() {
			this.showCropModal = false
			this.$nextTick(() => {
				bodyScroll.hidden()
			})
		},
		getCroppedImage() {
			this.isLoadingImage = true
			const { canvas } = this.$refs.cropper.getResult()
			const { width, height, top, left } = this.$refs.cropper.coordinates
			this.coordinates = this.$refs.cropper.coordinates
			this.croppedBase64 = canvas.toDataURL()
			this.sendCropEvent(width, height, left, top)
			this.hideCropModal()
			this.isLoadingImage = false
		},
		initStretcher({ stretcher, imageSize }) {
			const imageHeight = stretcher.clientWidth * (imageSize.height / imageSize.width)

			stretcher.style.height = `${Math.min(imageHeight, 513, window.innerHeight * 0.45)}px`
		},
		sendCropEvent(width, height, x, y) {
			this.$emit('crop', {
				width: Math.round(width),
				height: Math.round(height),
				x: Math.round(x) || 1,
				y: Math.round(y) || 1
			})
		}
	}
}
</script>

<style scoped lang="scss">
.cropper {
	&-wrapper {
		max-height: 45vh;
	}
}

.cropper-btngroup {
	margin-top: 43px;
}

.cropper-btngroup *:first-child {
	margin-bottom: 15px;
}
</style>

<style>
.edit-photo > .dialog {
	height: 100%;
}
.edit-photo .dialog-close-btn {
	display: none;
}

.edit-photo .dialog-back {
	display: block;
}

.post-photo-component .relative {
	padding-left: 50px;
	padding-right: 50px;
}
</style>
