import { defineStore } from 'pinia'
import messengerApi from '@/http/messenger'
import messageSound from '@/assets/sounds/notify_tone.mp3'
import threadSound from '@/assets/sounds/message_tone.mp3'

const audioMessage = new Audio(messageSound)
const audioThread = new Audio(threadSound)

export const useMessengerStore = defineStore('messenger', {
	state: () => ({
		messagesList: [],
		roomsList: [],
		activeThread: null
	}),
	getters: {},
	actions: {
		async getRoomsList() {
			const result = await messengerApi.getThreadsList()
			if (result) {
				this.roomsList = result.data
			}
		},
		toggleActiveThread(thread) {
			this.activeThread = thread
		},
		updateMessages(data) {
			this.messagesList = data
		},
		updateRooms(data) {
			this.roomsList = data
		},
		updateRoomsNewThread(newThread) {
			this.roomsList = [newThread, ...this.roomsList]
		},
		newMessage(msg, id, mock = false) {
			if (mock) {
				this.updateMessages([...this.messagesList, msg])
				return
			}

			if (this.activeThread && this.activeThread.id === msg.thread_id) {
				if (msg.owner_id === id) {
					const index = this.messagesList.findIndex((m) => m.temporary_id === msg.temporary_id)
					if (index !== -1) {
						this.messagesList.splice(index, 1, msg)
						return
					}
				}
				this.updateMessages([...this.messagesList, msg])
				this.markRead(msg.thread_id)
			}

			this.updateRoomMessage(msg)

			if (msg.owner_id !== id) {
				this.playSound()
			}
		},
		editMessage(msg) {
			const index = this.messagesList.findIndex((post) => post.id === msg.id)
			if (this.messagesList[index]) this.messagesList[index] = msg

			this.messagesList = [...this.messagesList]
			this.updateRoomMessage(msg)
		},
		updateRoomMessage(msg) {
			const index = this.roomsList.findIndex((thread) => thread.id === msg.thread_id)
			const room = this.roomsList[index]
			room.resources.latest_message = msg

			if (msg.owner_id === room.resources?.recipient?.provider_id) {
				room.unread = true
				room.unread_count += 1
			}
		},
		async removeRoomMessage(data) {
			const index = this.messagesList.findIndex((post) => +post.id === +data.message_id)
			if (index !== -1) {
				this.messagesList.splice(index, 1)
			}

			const roomIndex = this.roomsList.findIndex((room) => +room.id === +data.thread_id)
			if (roomIndex !== -1) {
				if (+this.roomsList[roomIndex].resources.latest_message.id === +data.message_id) {
					const res = await messengerApi.getMessages({ threadId: data.thread_id })
					const arr = [...res.data.reverse()]
					if (res.data.length) {
						this.roomsList[roomIndex].resources.latest_message = arr[res.data.length - 2]
					}
				}
			}
		},
		removeThread(data) {
			const index = this.roomsList.findIndex((thread) => +thread.id === +data.thread_id)
			if (index !== -1) {
				this.roomsList.splice(index, 1)
			}
		},
		threadAvatar(data) {
			const index = this.roomsList.findIndex((thread) => +thread.id === +data.thread.id)
			if (index !== -1) {
				this.roomsList[index].avatar = data.thread.avatar
			}
		},
		async newThread() {
			const { data } = await messengerApi.getThreadsList()
			this.roomsList = data
			this.playSound()
		},
		async markRead(threadId) {
			const room = this.roomsList.find((r) => r.id === threadId)
			room.unread = false
			room.unread_count = 0
			await messengerApi.markRead(threadId)
		},
		async updateReactions(data) {
			const idx = this.messagesList.findIndex((msg) => msg.id === data.message_id)
			if (idx !== -1) {
				const originalMessage = this.messagesList[idx]
				originalMessage.reactions = await messengerApi.getReactions(originalMessage.thread_id, data.message_id)
				this.messagesList.splice(idx, 1, originalMessage)
			}
		},
		async threadRead(data) {
			const room = this.roomsList.find((thread) => +thread.id === +data.thread_id)
			room.resources.latest_message.is_read = true
			this.messagesList
				.filter(
					(message) =>
						message?.owner_id === room.resources.latest_message.owner_id && message?.thread_id === data.thread_id
				)
				.map((message) => {
					message.is_read = true
					return message
				})
		},
		playSound() {
			if (this.activeThread) {
				audioMessage.play()
			} else {
				audioThread.play()
			}
		}
	}
})
