<template>
	<validation-provider tag="div" class="form-control" v-slot="{ errors, invalid, validated }" :name="id" :rules="rules">
		<label class="form-label" for="id">{{ label }}</label>
		<div class="form-field-wrap">
			<!-- refactor -->
			<!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
			<textarea
				ref="textarea"
				v-bind="$attrs"
				class="form-field h-full"
				:class="{ invalid: invalid && validated }"
				:value="value"
				@input="$emit('input', $event.target.value)"
				:maxlength="maxLength"
			/>
		</div>
		<validation-message :messages="errors" />
	</validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import isMobile from 'ismobilejs'
import ValidationMessage from '@/components/forms/ValidationMessage.vue'

export default {
	name: 'TextareaComponent',
	inheritAttrs: false,
	components: { ValidationProvider, ValidationMessage },
	props: {
		value: { type: String, default: '' },
		id: { type: String, required: true },
		label: { type: String, default: '' },
		rules: { type: Object, default: () => {} },
		maxLength: { type: Number, default: 600 }
	},
	mounted() {
		const { textarea } = this.$refs
		const gap = isMobile().apple.device ? 18 : 2
		textarea.style.height = `${textarea.scrollHeight + gap}px`
	}
}
</script>
