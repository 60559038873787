<template>
	<div class="menu flex row space">
		<div class="menu-item" style="order: 2" v-if="$auth.check('girl')">
			<input-media
				ref="mediaInput"
				@loadend="onLoadEnd"
				@error="onError($event)"
				:rules="{ minDimensions: [500, 217] }"
			/>
			<CreateButton :type="createButtonState" :percentage="progress" @openCreatePostDialog="openMedia" />
		</div>
		<div
			v-for="(link, i) in isConsumer ? consumerLinks : girlLinks"
			:key="link.icon"
			:style="{ order: i }"
			class="menu-item-wrap"
			@click="resetFilterAndScroll(link)"
		>
			<router-link :to="link.to" class="menu-item" :class="{ active: setActiveTab(link.to.name) }">
				<div class="icon" :class="[[link.icon], { indicator: link.icon === 'notifications' && hasNotifications }]" />
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useHomeStore } from '@/stores/home'
import { useCreatePostStore } from '@/stores/createPost'
import { createButtonTypes } from '@/constants'
import InputMedia from '@/components/create-post/InputMedia.vue'
import CreateButton from './CreateButton.vue'

const commonRoutes = [
	{
		to: { name: 'home' },
		icon: 'home',
		exactActiveClass: 'active'
	},
	{
		to: { name: 'search' },
		icon: 'search',
		activeClass: 'active'
	}
]

export default {
	name: 'NavMenu',
	components: { CreateButton, InputMedia },
	computed: {
		isConsumer() {
			return this.$auth.check('consumer')
		},
		...mapState(useVideoUploadStore, ['progress']),
		...mapState(useGirlProfileStore, { girlNotifications: 'notifications' }),
		...mapState(useProfileStore, {
			consumerId: 'id',
			consumerNotifications: 'notifications',
			consumerNickname: 'nickname'
		}),
		...mapState(useGirlProfileStore, {
			girlId: 'id',
			girlNickname: 'nickname',
			verification_status: 'verification_status'
		}),
		...mapState(useCreatePostStore, ['post']),
		hasNotifications() {
			return this.girlNotifications > 0 || this.consumerNotifications > 0
		},
		consumerLinks() {
			return this.consumerId
				? [
						...commonRoutes,
						{
							to: { name: 'consumer-notifications' },
							icon: 'notifications',
							activeClass: 'active'
						},
						{
							to: { name: 'consumer-profile', params: { id: this.consumerId, nickname: this.consumerNickname } },
							icon: 'profile',
							activeClass: 'active'
						}
					]
				: false
		},
		girlLinks() {
			return this.girlId
				? [
						...commonRoutes,
						{
							to: { name: 'author-notifications' },
							icon: 'notifications',
							activeClass: 'active'
						},
						{
							to: this.getAuthorProfile(),
							icon: 'profile',
							activeClass: 'active'
						}
					]
				: false
		},
		createButtonState() {
			if (this.progress === 0) {
				return createButtonTypes.default
			}
			if (this.progress === 100) {
				return createButtonTypes.ready
			}
			return createButtonTypes.loading
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['openCreatePostDialog', 'hideValidationDialog']),
		...mapActions(useHomeStore, ['resetFilter']),
		...mapActions(useVideoUploadStore, ['initUploader', 'addFile']),
		openMedia() {
			if (this.createButtonState === createButtonTypes.default) {
				this.$refs.mediaInput.$refs.media.value = ''
				this.$refs.mediaInput.$refs.media.click()
			}
			if (this.createButtonState === createButtonTypes.ready) {
				this.openCreatePostDialog()
			}
		},
		onLoadEnd(fileInfo) {
			if (fileInfo && fileInfo.type === 'video') {
				this.initUploader(this.$refs.browse)
				this.addFile(fileInfo.file)
			}
			if (fileInfo && fileInfo.type === 'image') {
				this.post.photo = fileInfo.file
				this.openCreatePostDialog()
			}
		},
		getAuthorProfile() {
			if (!this.girlNickname || this.verification_status === 'needs') {
				return { name: 'author-profile-id', params: { id: this.girlId } }
			}
			return { name: 'author-profile', params: { nickname: this.girlNickname, id: this.girlId } }
		},
		resetFilterAndScroll(link) {
			if (link.to.name === 'home') {
				this.resetFilter()
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			}
		},
		setActiveTab(tabName) {
			return this.$route.name === tabName
		}
	}
}
</script>
