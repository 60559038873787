import { extend } from 'vee-validate'
import { required, email, confirmed, min, max, ext, min_value, max_value, regex } from 'vee-validate/dist/rules'
import digitsOfNumber from '@/helpers/digitsOfNumber'

function getImageSizes(file) {
	const URL = window.URL || window.webkitURL
	return new Promise((resolve) => {
		const image = new Image()
		image.onerror = () => resolve(false)
		image.onload = () => resolve(image)
		image.src = URL.createObjectURL(new Blob(file))
	})
}

const requiredTexts = {
	email: 'Введите адрес электронной почты',
	password: 'Введите пароль',
	passwordConfirmation: 'Пароль необходимо повторить',
	gesture: 'Загрузите фото с жестом',
	photoWithPassport: 'Загрузите фото с паспортом',
	nickname: 'Введите имя в приложении',
	account_number: 'Введите номер карты',
	secret_number: 'Введите номер карты'
}

const emailTexts = {
	email: 'Некорректный адрес электронной почты'
}

const regexTexts = {
	account_number: 'Некорректный номер',
	secret_number: 'Некорректный номер'
}

const passwordTexts = {
	password: 'Пароль должен состоять из цифр и латинских букв'
}

const confirmedTexts = {
	passwordConfirmation: 'Пароли не совпадают'
}

const minTexts = {
	nickname: (length) => `Никнейм должен содержать минимум ${length} символа`,
	about_yourself: (length) => `Поле о себе должно содержать минимум ${length} символов`
}

const maxTexts = {
	nickname: (length) => `Никнейм должен содержать максимум ${length} символа`,
	about_yourself: (length) => `Поле о себе должно содержать максимум ${length} символа`,
	cardName: (length) => `Название карты должно содержать максимум ${length} символа`
}

const minValueTexts = {
	amount: (value) => `Минимальная сумма — ${digitsOfNumber(value)} ₽`
}

const maxValueTexts = {
	amount: () => `Сумма превышает разовый лимит`
}

extend('required', {
	...required,
	message: (field) => requiredTexts[field]
})

extend('regex', {
	...regex,
	message: (field) => regexTexts[field]
})

extend('email', {
	...email,
	message: (field) => emailTexts[field]
})

extend('password', {
	validate: (value) => /^[a-zA-Z0-9]+$/.test(value),
	message: passwordTexts.password
})

extend('confirmed', {
	...confirmed,
	message: (field) => confirmedTexts[field]
})

extend('minDimensions', {
	validate: async (file, [width, height]) => {
		if (file[0].type.includes('video')) return true
		const image = await getImageSizes(file)
		return image.width >= width && image.height >= height
	},
	message: (file, params) => {
		const width = params[0]
		const height = params[1]
		return `Фото слишком маленькое. Минимальный размер: ${width}px в ширину и ${height}px в высоту.`
	}
})

extend('noOnlySpaces', {
	validate: (string) => /.*\S.*/gm.test(string),
	message: 'Введите какой-нибудь символ'
})

extend('aspectRatio', {
	validate: async (file) => {
		if (file[0].type.includes('video')) return true
		const { width, height } = await getImageSizes(file)
		return Math.max(width, height) / Math.min(width, height) < 3
	},
	message: 'Нельзя использовать фотографии, у которых одна из сторон в несколько раз превышает другую'
})

extend('size', {
	validate: async (file, size) => {
		if (file[0].type.includes('video')) return true
		const fileSize = file[0].size
		return fileSize < size
	},
	message: () => {
		const maxSize = Number(import.meta.env.VITE_MAX_IMG_SIZE_IN_BYTES)
		return `Фото слишком большое. Максимальный размер — ${maxSize / 1000000} Мб.`
	}
})

extend('min', {
	...min,
	message: (field, { length }) => minTexts[field](length)
})

extend('max', {
	...max,
	message: (field, { length }) => maxTexts[field](length)
})

extend('min_value', {
	...min_value,
	message: (field, { minValue }) => minValueTexts[field](minValue)
})

extend('max_value', {
	...max_value,
	message: (field, { maxValue }) => maxValueTexts[field](maxValue)
})

extend('balance', {
	...max_value,
	message: () => 'На балансе недостаточно средств'
})

extend('imageExt', {
	...ext,
	message: () => 'Неизвестный формат файла. Допустимые форматы: JPEG/JPG, PNG, HEIC, HEIF, MP4, MOV'
})
