import { markRead } from './markRead'
import { getThreadsList } from './privatesList'
import { search } from './search'
import { locateThread } from './locate'
import { initializeThread } from './privates'
import { getMessages } from './getMessages'
import { sendTextMessage, sendImageMessage, sendVideoMessage } from './sendMessage'
import { editMessage } from './editMessage'
import { getReactions, setReaction, removeReaction } from './reaction'
import { getRecommendedListConsumer } from './recommendedListConsumer'
import { getRecommendedListGirl } from './recommendedListGirl'

export default {
	getMessages,
	getThreadsList,
	getRecommendedListConsumer,
	getRecommendedListGirl,
	search,
	locateThread,
	initializeThread,
	sendTextMessage,
	sendImageMessage,
	sendVideoMessage,
	editMessage,
	getReactions,
	setReaction,
	removeReaction,
	markRead
}
