function auto() {
	document.body.style.overflow = 'auto'
	document.documentElement.style.overflow = 'auto'
	document.body.style.touchAction = 'auto'
}

function hidden() {
	document.body.style.overflow = 'hidden'
	document.documentElement.style.overflow = 'hidden'
	document.body.style.touchAction = 'none'
}

export default { auto, hidden }
